import {  Injectable } from '@angular/core';
import { DealerModel, DealerRequestModel } from '../_models/dealer.model';
import { HttpMethodsService } from './http-methods.services';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DealersService {
  constructor(private _httpService: HttpMethodsService) {}

  findOne(id: string) {
    return this._httpService.get(`dealer`, { id });
  }
  findAll(filters: any = {}) {
    return this._httpService.get('dealers', {}, filters).pipe(
      map((res: any) => {
        return {
          data: res?.data.map((r: any) => new DealerModel(r)),
          total: res?.total,
        };
      })
    );
  }
  create(data: DealerRequestModel) {
    return this._httpService.post('dealers', data);
  }

  update(id: string, data: DealerRequestModel) {
    return this._httpService.patch('dealer', data, { id });
  }

  remove(id: string) {
    return this._httpService.delete('dealer', { id });
  }
}
